const identityAppsData = {
  apps: [
    {
      title: 'Web3Modal Demo',
      link: 'https://codepen.io/polats/embed/dyGXPOz'
    },
  ],
  repoLink: 'https://github.com/Web3Modal/web3modal-vanilla-js-example'
}

export default identityAppsData
